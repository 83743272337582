@charset "utf-8";

// Stories
.story {
  background: rgba(0,0,0,0.5);
  width: 90%;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
}

.story-post {
  border: solid 2px black;
  background: rgba(0,0,0,0.9);
  width: 100%;
  overflow: hidden;
}
  


//---

// Front Page Styles
.front-post {
  width: relative 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}

.front-title {
 width: relative 100%;
 height: 60px;
 padding-left: 5px;
 background: rgba(13,13,11,0.9);
 white-space: nowrap;
 overflow: hidden;
 text-overflow: ellipsis;
}
//---


// Our variables
$base-font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;

$spacing-unit:     30px;

$text-color:       #9f9999;
$background-color: #2d2824;
$brand-color:      #5f7a13;

$grey-color:       #838ea0;
$grey-color-light: lighten($grey-color, 40%);
$grey-color-dark:  darken($grey-color, 25%);

// Width of the content area
$content-width:    800px;

$on-palm:          600px;
$on-laptop:        800px;



// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: calc($spacing-unit / 2);
//         padding-left: calc($spacing-unit / 2);
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}



// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
